header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
}

#companyName {
  float: left;
  width: 5em;
  padding: 1rem 2rem;
  letter-spacing: 2px;
  text-align: left;
}

#companyName a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.menuItems {
  list-style-type: none;
  display: none;
  justify-content: space-evenly;
}

.menuItems a {
  text-decoration: none;
  font-size: 30px;
  color: #00aded;
}

.menuItems a:hover {
  color: #00adedb8;
}

.menuItems li {
  margin: 10px 0;
}

.menuItems.open {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  margin: 0;
  background: #141e55f2;
  z-index: 1;
}

.hamburger {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
  margin-right: 10px;
  z-index: 2;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 1px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.9s;
}

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-6px, -7px);
}

@media only screen and (min-width: 1000px) {
  /****************\\\\\\\\\ Header Large /////////****************/

  .hamburger {
    display: none;
  }

  .menuItems {
    display: inline-flex;
    width: 430px;
  }

  .menuItems a {
    font-size: 24px;
    color: #ffffff;
  }

  .menuItems a:hover {
    color: #e9ecbb;
  }

  #companyName a:hover {
    color: #e9ecbb;
  }
}
